/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import { assetImages } from 'constants';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import SentHeader from '../Header/SentHeader';
import ConversationFilterPopup from '../Popup/ConversationFilterPopup';
import ConversationNewMailPopup from 'components/Common/Popup/ConversationNewMailPopup';
import ConversationMailPopup from 'components/Common/Popup/ConversationMailPopup';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import { crmCommonHeaderLinkLists } from 'helper/CrmHelper/CrmHelper';
import { useTranslation } from 'react-i18next';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const SentListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [tagList, setTagList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mailList, setMailList] = useState([]);
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMailIds, setSelectedMailIds] = useState([]);
  const [selectedMailId, setSelectedMailId] = useState(null);
  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterSubject, setfilterSubject] = useState('');
  const [filterReciever, setfilterReciever] = useState(null);
  const [filterTemplates, setfilterTemplates] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterHasAttachment, setFilterHasAttachment] = useState(false);
  const [filterHasOpened, setFilterHasOpened] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //kpis to show in sent header
  const [mailCount, setMailCount] = useState(0);
  const [mailOpened, setMailOpened] = useState(0);
  const [tagCounts, setTagCounts] = useState([]);


  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: 'CRM', link: '/admin/crm/conversation/list' },
    { title: t('Conversation') },
  ];

  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  const getAllMailTemplates = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_MAIL_TEMPLATES + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTemplateList(
          response.data.map(template => ({
            label: template.name,
            value: template._id,
          }))
        );
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  const getAllSentMails = async () => {
    setRowSelection({});
    setSelectedMailIds([]);

    try {
      setIsLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_SENT_MAIL}?token=${token}`;

      if (filterDateRange != '') {
        requestUrl = requestUrl + `&maildate=${filterDateRange}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&maillabels=${filterTags}`;
      }

      if (filterTemplates.length > 0) {
        requestUrl = requestUrl + `&mailtemplates=${filterTemplates}`;
      }

      if (filterReciever) {
        requestUrl = requestUrl + `&mailreciever=${filterReciever}`;
      }

      if (filterSubject != '') {
        requestUrl = requestUrl + `&mailsubject=${filterSubject}`;
      }

      if (filterHasAttachment == true) {
        requestUrl = requestUrl + `&attachment=1`;
      }
      if (filterHasOpened == true) {
        requestUrl = requestUrl + `&open=1`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {

        setMessageType('success');
        resetFilterData();
        setMailList(response.data);
        setMailCount(response.data.length);
        setMailOpened(response.mailopen);

        const topLabels = response.groupedLabelsArray ? response.groupedLabelsArray.slice(0, 5) : [];
        setTagCounts(topLabels);

      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  const changeStatusHandler = async () => {
    if (selectedMailIds.length > 0) {
      let responseArr = [];

      for (let mailId of selectedMailIds) {
        try {
          let mailData = {
            senderstatus: '0',
          };
          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_MAIL +
            `/${mailId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, mailData);

          if (response.status) {
            setMessageType('success');
            responseArr.push(true);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllSentMails();
        }, 2000);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select mail for delete');
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setfilterSubject('');
    setfilterTemplates([]);
    setFilterTags([]);
    setfilterReciever(null);
    setFilterHasAttachment(false);
    setFilterHasOpened(false);
  };

  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterSubject != '' ||
      filterTags.length > 0 ||
      filterTemplates.length > 0 ||
      filterHasAttachment == true ||
      filterHasOpened == true ||
      filterReciever
    ) {
      getAllSentMails();
    }
  }, [
    filterSubject,
    filterTemplates,
    filterDateRange,
    filterTags,
    filterHasAttachment,
    filterReciever,
    filterHasOpened
  ]);

  useEffect(() => {
    getAllSentMails();
    getAllTags();
    getAllMailTemplates();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasEmail"
            aria-controls="offcanvasEmail"
            onClick={() => {
              setSelectedMailId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'subject',
        header: t('Mail Subject'),
         Cell: ({ row }) => (
          <div>
           
            {
              row.original.mailopened ?(
                <span className="material-symbols-outlined icon-md text-primary font-weight-bold">
                  done_all
                </span>
              ) : row.original.tracker == "1" ? (
                <span className="material-symbols-outlined icon-md text-success">
                  done
                </span> 
              ) : (
                <span className="material-symbols-outlined icon-md text-danger">
                  error_outline
                </span>
              )
            }
            <span>{row.original.subject}</span>
          </div>
         ),
      },
      {
        accessorKey: 'receivermail',
        header: t('Sent To'),
        Cell: ({ row }) => (
          <div className="sent-from border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={
                    row.original.recieverimage === ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.recieverimage
                  }
                  alt=""
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>
                {row.original.receivername
                  ? row.original.receivername
                  : row.original.receivermail}
              </p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'maildate',
        header: t('Date'),
      },
      {
        accessorKey: 'linktrackerstring',
        header: t('Link Tracker'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.linktrackerstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.linktrackerstring
                  .split(' , ')
                  .map((tracker, index) => {
                    return (
                      <li
                        key={index}
                        className="px-2 py-1 gradient-light rounded-5"
                      >
                        {tracker}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Tags'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedMailIds(selectedIdsArray);
    } else {
      setSelectedMailIds([]);
    }
  }, [rowSelection]);

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') &&
      moduleAccess.includes('MOD_CONVERSATION'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper crm-conversation-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Conversation')}
          />
          <div className="container-fluid px-lg-5">
            <SentHeader
              breadCrumbText={breadcrumbText}
              reloadList={getAllSentMails}
            />
            <div className="row">
              <div className="col-lg-12 mb-4 mb-lg-0">
                <ul
                  className="nav nav-tabs d-inline-flex p-1 body-bg flex-nowrap fs-md lh-1 fw-semibold border-0 text-nowrap rounded-10 overflow-auto mb-2"
                  id="mailTab"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/admin/crm/conversation/recieved"
                      className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10"
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        archive
                      </span>
                      <span className="d-block">{t('Received')}</span>
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/admin/crm/conversation/sent"
                      className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10 active"
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        unarchive
                      </span>
                      <span className="d-block">{t('Sent')}</span>
                    </Link>
                  </li>
                  
                </ul>
                <ul className="d-inline-flex ms-2">
                   <li className="me-2" role="presentation">
                       Sent : <span className="text-primary">{mailCount}</span>
                   </li>
                   <li className="me-2" role="presentation">
                       Opened : <span className="text-primary">{mailOpened}</span>
                   </li>

                   {tagCounts && tagCounts.map((labelCount, index) => {
                      const label = Object.keys(labelCount)[0]; // Get the label
                      const count = labelCount[label]; // Get the count

                      return (
                        <li key={index} className="me-2">
                          {label} : <span className="text-primary">{count}</span>
                        </li>
                      );
                    })}
                   
                </ul>
                
              </div>

              <div>
                {isLoading ? (
                  <div className="placeholder-glow d-flex flex-column gap-4">
                    <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                    <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                    <span className="placeholder placeholder-lg bg-secondary col-4"></span>
                  </div>
                ) : (
                  <div className="table-wrapper">
                    <MaterialReactTable
                      columns={columns} // map columns to be displayed with api data,
                      data={mailList} // data from api to be displayed
                      enableGrouping
                      enableRowSelection // enable showing checkbox
                      getRowId={row => row._id} // map which value to select with row checkbox
                      onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                      state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                      defaultColumn={{
                        minSize: 20,
                        maxSize: 200,
                        size: 50, //make columns wider by default
                      }}
                      muiTableContainerProps={{
                        sx: {
                          maxHeight: '60vh',
                        },
                      }}
                      enableStickyHeader
                    />
                  </div>
                )}
              </div>

            </div>
          </div>
         
        </section>
        {/* ==== modals and popup area ==== */}
        {/* --- filter popup --- */}
        <ConversationFilterPopup
          tagList={tagList}
          templateList={templateList}
          setFilterDateRange={setFilterDateRange}
          setfilterSubject={setfilterSubject}
          setfilterTemplates={setfilterTemplates}
          setFilterTags={setFilterTags}
          setfilterReciever={setfilterReciever}
          setFilterHasAttachment={setFilterHasAttachment}
          setFilterHasOpened={setFilterHasOpened}
        />
        {/* --- new mail popup --- */}
        <ConversationNewMailPopup
          reloadList={() => {
            setTimeout(() => {
              getAllSentMails();
            }, 2500);
          }}
          contactPersonEmail={''}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ---  mail popup --- */}
        <ConversationMailPopup
          selectedMailId={selectedMailId}
          resetMailId={() => {
            setSelectedMailId(null);
          }}
        />

        <AddLabelModal
          moduleName="conversation"
          selectedIds={selectedMailIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllSentMails();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedMailIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('conversation')}
        />
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default SentListBody;
