export const getDateFormatInSpanish = (dateString) => {
  if (dateString) {
    const parts = dateString.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate; // Output: 17/10/2018
  }
};

export const getUpdatedConvertedDate = (dateString) => {
  const createdAt = new Date(dateString);

  // Formatting date
  const formattedDate = createdAt.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Formatting time
  const formattedTime = createdAt.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Adjusting time format to AM/PM
  const amPmTime = formattedTime.replace(/^(\d{1,2}:\d{2}) (AM|PM)$/, "$1 $2");

  // Combining date and time
  const formattedDateTime = `${formattedDate} at ${amPmTime}`;

  return formattedDateTime; // Output: 05/03/2024 at 01:04 PM
};

export const createdAtToDateConverter = (dateValue) => {
  // Assuming your MongoDB document has a field named 'createdAt' which holds the timestamp
  let createdAtTimestamp = new Date(dateValue); // Example timestamp, replace with your actual timestamp from MongoDB

  // Convert timestamp to dd/mm/yyyy format
  let day = createdAtTimestamp.getDate();
  let month = createdAtTimestamp.getMonth() + 1; // January is 0, so we add 1 to get the correct month
  let year = createdAtTimestamp.getFullYear();

  // Ensure leading zeros if necessary
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  // Formatted date in dd/mm/yyyy format
  let formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};


// resize the image and convert to height and width as asked in params
// use for resizing large image to fit into BLOB url
export const resizeOrCompressImage = (imageData, targetWidth, targetHeight) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
      canvas.toBlob(
        function (blob) {
          resolve(blob);
        },
        "image/jpeg",
        0.8 // Set the quality here (0.8 for 80%)
      );
    };

    img.src = imageData;
  });
};



//spanish amount format
export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

export const getTomorrowDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formattedDate = tomorrow.toISOString().split("T")[0];
  return formattedDate;
};

// export array of years
// input params '2024-12-25'
export const getYearsBetweenDates = (startDate, endDate) => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
}

export const hoursArr = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
