/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";

// Material UI table
import { MaterialReactTable } from "material-react-table";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import {getYearsBetweenDates} from "helper/Common/CommonHelper";

const DashboardCustomerTab = ({
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,
  zipcodes,
  operationType,

  applyFilter,
  refreshData,
}) => {

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");


  const [miltiTicketsDiffDate, setMultiTicketsDiffDate] = useState(0);

  const [ticketsWithUniqueEmails, setTicketsWithUniqueEmails] = useState(0);
  const [ticketsWithZipcode, setTicketsWithZipcode] = useState(0);
  const [ticketsInMadrid, setTicketsInMadrid] = useState(0);

  const [yearlyDataLoading, setYearlyDataLoading] = useState(false);
  const [yearlyData, setYearlyData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);



  // structure the params received from filter in url params
  const getFilterParamsInUrl = (opstart = null, opend = null, showparams = false) => {

    let urlParams = "";

    if (applyFilter === false && showparams === false) {
      return urlParams;
    }

    if (startDate !== "" && endDate !== "") {
      urlParams += `&filterstartdate=${startDate}&filterenddate=${endDate}`;
    }


    if (opstart !== null && opend !== null) {
      // manage this value by function params
      urlParams += `&filteropstartdate=${opstart}&filteropenddate=${opend}`;

    }else{
      // manage by states
      if (operationStartDate !== "" && operationEndDate !== "") {
        urlParams += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
      }
    }

    

    if (organizations && organizations.length > 0) {
      organizations.forEach((data) => {
        urlParams += `&filterorganizations[]=${encodeURIComponent(data)}`;
      });
    }

    if (enclosures && enclosures.length > 0) {
      enclosures.forEach((data) => {
        urlParams += `&filterenclosures[]=${encodeURIComponent(data)}`;
      });
    }

    if (sectors && sectors.length > 0) {
      sectors.forEach((data) => {
        urlParams += `&filtersectors[]=${encodeURIComponent(data)}`;
      });
    }

    if (eventTypes && eventTypes.length > 0) {
      eventTypes.forEach((data) => {
        urlParams += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      });
    }

    if (events && events.length > 0) {
      events.forEach((data) => {
        urlParams += `&filterevent[]=${encodeURIComponent(data)}`;
      });
    }

    if (channels && channels.length > 0) {
      channels.forEach((data) => {
        urlParams += `&filterchannel[]=${encodeURIComponent(data)}`;
      });
    }

    if (zones && zones.length > 0) {
      zones.forEach((data) => {
        urlParams += `&filterzone[]=${encodeURIComponent(data)}`;
      });
    }

    if (zipcodes && zipcodes.length > 0) {
      zipcodes.forEach((data) => {
        urlParams += `&filterzipcode[]=${encodeURIComponent(data)}`;
      });
    }

    if (operationType && operationType.length > 0) {
      operationType.forEach((data) => {
        urlParams += `&filteroperationtype[]=${encodeURIComponent(data)}`;
      });
    }

    console.log("urlParams", urlParams);

    return urlParams;
  };

  //reset all data
  const resetHandler = () => {
    
    setMultiTicketsDiffDate(0);
    setTicketsWithUniqueEmails(0);
    setTicketsWithZipcode(0);
    setTicketsInMadrid(0);

  };

  
  
  // API Request --- get Data By Madrid ----------------------------
  const getDataByMadrid = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_ONLY_MADRID +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataByMadrid url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataByMadrid", response);

      if (response.status) {
        const madridTicketCount = response.data?.totalCount??'0';

        setTicketsInMadrid(madridTicketCount);
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get Data With Email ----------------------------
  const getDataWithEmail = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_WITH_EMAIL +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataWithEmail url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataWithEmail", response);

      if (response.status) {
        const uniqueEmailCount = response.data?.totalUniqueEmails??'0';

        setTicketsWithUniqueEmails(uniqueEmailCount);
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get Data With Zipcode ----------------------------
  const getDataWithZipcode = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_WITH_ZIPCODE +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataWithZipcode url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataWithZipcode", response);

      if (response.status) {
        const totalCount = response.data?.totalCount??'0';

        setTicketsWithZipcode(totalCount);
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get Data With Date Diff ----------------------------
  const getDataWithDateDiff = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_DIFF_DATE +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataWithDateDiff url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataWithDateDiff XXXXXXXXXXX", response);

      if (response.status) {
        setMultiTicketsDiffDate(response.data.length)
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // get graph data year wise for customer overview
  const results = {};

  let years = [2023, 2024];

  // fetch yearly data --------------------------------------------
  const fetchYearlyData = async() => {

    setYearlyDataLoading(true);
    setYearlyData([]);

    if (operationStartDate !== "" && operationEndDate !== "") {
      years = getYearsBetweenDates(operationStartDate, operationEndDate)
    }

    for (const year of years) {
      
      let startDate = `${year}-01-01`;
      let endDate = `${year}-12-31`;

      if (operationStartDate !== "" && operationEndDate !== "") {
        if(operationStartDate > startDate){
          startDate = operationStartDate;
        }

        if(operationEndDate < endDate){
          endDate = operationEndDate;
        }
      }

      // Call the API functions with the updated parameters
      const madridResult = await getDataByMadridYearly(startDate, endDate);
      const emailResult = await getDataWithEmailYearly(startDate, endDate);
      const zipcodeResult = await getDataWithZipcodeYearly(startDate, endDate);
      const dateDiffResult = await getDataWithDateDiffYearly(startDate, endDate);

      // Store the results in the object
        const results = {
          [year]: {
            madrid: madridResult,
            email: emailResult,
            zipcode: zipcodeResult,
            dateDiff: dateDiffResult,
          },
        };

        // Update the state with the new results
        setYearlyData((prevData) => ({
          ...prevData,
          ...results,
        }));

        
    }

    setYearlyDataLoading(false);

    console.log('yearlyData', yearlyData)
  }

  // API Request --- get Data By Madrid ----------------------------
  const getDataByMadridYearly = async (startDate, endDate) => {
    try {
      let urlParams = getFilterParamsInUrl(startDate, endDate, true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_ONLY_MADRID +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("YEARLY getDataByMadrid url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataByMadrid", response);

      if (response.status) {
        const madridTicketCount = response.data?.totalCount??'0';

        return madridTicketCount;
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);

      return 0;
    }
  };

  // API Request --- get Data With Email ----------------------------
  const getDataWithEmailYearly = async (startDate, endDate) => {
    try {
      let urlParams = getFilterParamsInUrl(startDate, endDate, true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_WITH_EMAIL +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataWithEmail url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataWithEmail", response);

      if (response.status) {
        const uniqueEmailCount = response.data?.totalUniqueEmails??'0';

        return uniqueEmailCount;
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);

      return 0
    }
  };

  // API Request --- get Data With Zipcode ----------------------------
  const getDataWithZipcodeYearly = async (startDate, endDate) => {
    try {
      let urlParams = getFilterParamsInUrl(startDate, endDate, true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_WITH_ZIPCODE +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataWithZipcode url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataWithZipcode", response);

      if (response.status) {
        const totalCount = response.data?.totalCount??'0';

        return totalCount;
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);

      return 0;
    }
  };

  // API Request --- get Data With Date Diff ----------------------------
  const getDataWithDateDiffYearly = async (startDate, endDate) => {
    try {
      let urlParams = getFilterParamsInUrl(startDate, endDate, true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_DIFF_DATE +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getDataWithDateDiff url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response getDataWithDateDiff", response);

      if (response.status) {
        const diffData = response.data?.length??0;
        return diffData;
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
      return 0;
    }
  };



  // call all api on intial loading
  useEffect(() => {

    // getDataByMadrid();
    // getDataWithEmail();
    // getDataWithZipcode();
    // getDataWithDateDiff();

    fetchYearlyData();

  }, []);


  // call all api if filter is applied again
  useEffect(() => {
    
    console.log('Refresh filter', refreshData);
    console.log('apply filter', applyFilter);

      // call api to get filtered data
      fetchYearlyData();

  }, [startDate, 
      endDate,
      operationStartDate, 
      operationEndDate,
      channels, 
      organizations, 
      enclosures, 
      sectors,
      eventTypes,
      events,
      operationType,
      channels,
      zones,
      zipcodes
    ]);


  // Transform yearlyData into barChartData format
  useEffect(() => {

    const transformData = () => {
      const transformedData = [
        { xlabel: "with email" },
        { xlabel: "with zipcode" },
        { xlabel: "from madrid" },
        { xlabel: "distinct event" }
      ];

      for (const year in yearlyData) {
        transformedData[0][year] = yearlyData[year].email;
        transformedData[1][year] = yearlyData[year].zipcode;
        transformedData[2][year] = yearlyData[year].madrid;
        transformedData[3][year] = yearlyData[year].dateDiff;
      }

      setBarChartData(transformedData);
    };

    transformData();

  }, [yearlyData]); // Re-run this effect whenever yearlyData changes


  // bargraph for showing yearly data ----------------------
  const chartSetting = {
  yAxis: [
    {
      label: 'rainfall (mm)',
    },
  ],  
  height: 500,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};
const valueFormatter = (value) => `${value}`;
// bar graph ends ------------------------------------

  return (
    <div className="activity-container py-4">
      <div className="row">
        <div className="col-md-12 col-lg-12 mb-4">
          <div className="card-content p-3 h-100 bg-white rounded-10 shadow-sm">
            

            {/* Customer data */}
            {/* <div className="head d-flex align-items-start justify-content-between gap-3 mb-4">
              
              <div className="title col-lg-4">
                <h3 className="mb-0">Customers with email</h3>
                <p className="d-flex align-items-center gap-1 mb-1">
                  <span className="d-block text-success fw-semibold">
                    {ticketsWithUniqueEmails}
                  </span>
                  <span className="d-block fs-xs fw-medium text-gray">
                    customers
                  </span>
                </p>
              </div>

              <div className="title col-lg-4">
                <h3 className="mb-0">Customers with Zipcode</h3>
                <p className="d-flex align-items-center gap-1 mb-1">
                  <span className="d-block text-success fw-semibold">
                    {ticketsWithZipcode}
                  </span>
                  <span className="d-block fs-xs fw-medium text-gray">
                    customers
                  </span>
                </p>
              </div>

              <div className="title col-lg-4">
                <h3 className="mb-0">Customers from Madrid</h3>
                <p className="d-flex align-items-center gap-1 mb-1">
                  <span className="d-block text-success fw-semibold">
                    {ticketsInMadrid}
                  </span>
                  <span className="d-block fs-xs fw-medium text-gray">
                    customers
                  </span>
                </p>
              </div>
            </div>

            <div className="head d-flex align-items-start justify-content-between gap-3 mb-4">

              <div className="title col-lg-4">
                <h3 className="mb-0">Customers with Distinct events </h3>
                <p className="d-flex align-items-center gap-1 mb-1">
                  <span className="d-block text-success fw-semibold">
                    {miltiTicketsDiffDate} 
                  </span>
                  <span className="d-block fs-xs fw-medium text-gray">
                    customers
                  </span>
                </p>
              </div>

            </div> */}

            

            <div className="body">

              <div className="table-responsive">
                <div className="table-wrapper">
                  
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Year</th>
                        <th scope="col">Customers from Madrid</th>
                        <th scope="col">Customers with email</th>
                        <th scope="col">Customers with zipcode</th>
                        <th scope="col">Customers of distinct events</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(yearlyData).map((year) => (
                        <tr key={year}>
                          <td>{year}</td>
                          <td>{yearlyData[year]?.madrid??'-'}</td>
                          <td>{yearlyData[year]?.email??'-'}</td>
                          <td>{yearlyData[year]?.zipcode??'-'}</td>
                          <td>{yearlyData[year]?.dateDiff??'-'}</td>
                        </tr>
                      ))}

                      {yearlyDataLoading && (
                        <tr>
                          <td colSpan="5">
                            <div
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">Loading...</span>
                            </div>
                            Loading...
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </table>


                </div>
              </div>

              <div>
                {/* Barchat  */}
                {barChartData.length > 0 ?
                (
                  <BarChart
                    dataset={barChartData}
                    xAxis={[{ scaleType: 'band', dataKey: 'xlabel' }]}
                    series={[
                      { dataKey: '2023', label: '2023', valueFormatter, color: '#b5d6fd' },
                      { dataKey: '2024', label: '2024', valueFormatter, color: '#EE523C'},
                    ]}
                    {...chartSetting}
                  />
                )
                :''}
                

              </div>


              


            </div>


          </div>
        </div>

   

      </div>
    </div>
  );
};

export default DashboardCustomerTab;
