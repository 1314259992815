/* eslint-disable */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";
//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import CRMLeadListHeader from "../Header/CRMLeadListHeader";
import CRMLeadListFilterPopup from "../Popup/CRMLeadListFilterPopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

//import images
import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";

//import common header objects
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";
import AddToListModal from "components/Common/Modal/AddToListModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddAlertModal from "components/Common/Modal/AddAlertModal";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";
import LinkedinHelperModal from "components/Common/Modal/LinkedinHelperModal";
import VeriFyEmailModal from "../Modal/VeriFyEmailModal";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const CRMLeadListBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const { screenHeight } = useContext(GlobalProvider);

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const [isLoading, setIsLoading] = useState(false);
  const [leadsDataList, setleadsDataList] = useState([]);
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterLeadName, setFilterLeadName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterCompany, setFilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterTransactionModule, setFilterTransactionModule] = useState("");
  const [filterTransactionElement, setFilterTransactionElement] = useState("");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterMinSalary, setFilterMinSalary] = useState("");
  const [filterMaxSalary, setFilterMaxSalary] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterSubsribeStatus, setFilterSubsribeStatus] = useState("");

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectLeadMails, setSelectLeadMails] = useState("");
  const [selectedLeadNames, setSelectedLeadNames] = useState("");

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  const [isVerifying, setIsVerifying] = useState(false);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [enetredNumberForSelect, setEnetredNumberForSelect] = useState("");

  const [selectedNumberValidationMessage, setSelectedNumberValidationMessage] =
    useState("");

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  //function for get all lead records
  const getAllLeads = async (isFilter) => {
    setRowSelection({});
    setSelectedLeadIds([]);
    setSelectLeadMails("");
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_LEADS +
        `?token=${token}&roleslug=${userInfo.role ? userInfo.role?.slug : ""}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterLeadName != "") {
        requestUrl = requestUrl + `&filtername=${filterLeadName}`;
      }

      if (filterEmail != "") {
        requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestUrl = requestUrl + `&filterphone=${filterPhone}`;
      }

      if (filterLocation != "") {
        requestUrl = requestUrl + `&filterlocation=${filterLocation}`;
      }

      if (filterPosition != "") {
        requestUrl = requestUrl + `&filterposition=${filterPosition}`;
      }

      if (filterCompany != "") {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterMinSalary != "") {
        requestUrl = requestUrl + `&filterminsalary=${filterMinSalary}`;
      }

      if (filterMaxSalary != "") {
        requestUrl = requestUrl + `&filtermaxsalary=${filterMaxSalary}`;
      }

      if (filterTransactionModule != "") {
        requestUrl =
          requestUrl + `&filtertransactionmodule=${filterTransactionModule}`;
      }

      if (filterTransactionElement != "") {
        requestUrl =
          requestUrl + `&filtertransactionelement=${filterTransactionElement}`;
      }

      if (filterSkills.length > 0) {
        requestUrl = requestUrl + `&filterskills=${filterSkills}`;
      }

      if (filterLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterLabels}`;
      }

      if (filterSubsribeStatus !== "") {
        requestUrl =
          requestUrl + `&filtersubsribestatus=${filterSubsribeStatus}`;
      }

      requestUrl += `&isfilter=${isFilter}`;

      console.log("url of lead list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in lead list------->", response);

      if (response.status) {
        setleadsDataList(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);

      setIsLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedLeadIds) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_LEAD +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl);

        if (response.status) {
          setMessageType("success");
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    }
    getAllLeads(false);
  };

  //verify emails
  const emailVerificationHandler = async () => {
    if (selectedLeadIds.length > 0) {
      setIsVerifying(true);
      try {
        let leadData = {
          leadids: selectedLeadIds,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_VERIFY_LEAD_EMAILS + `?token=${token}`;

        const response = await postData(requestUrl, leadData);
        setIsVerifying(false);

        let myModal = document.querySelector("#veriFyEmail");
        let modal = bootstrap.Modal.getInstance(myModal);
        modal.hide();

        if (response.status) {
          setSelectedLeadIds([]);
          setRowSelection({});
          setMessageType("success");

          getAllLeads(false);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        console.log("error in email verification handler", error.message);
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please select at least one lead"));
    }
    setShowAlert(true);
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterSubsribeStatus("");
    setfilterStartDate("");
    setfilterEndDate("");
    setFilterLeadName("");
    setFilterEmail("");
    setFilterLocation("");
    setFilterPhone("");
    setFilterCompany("");
    setFilterPosition("");
    setFilterTransactionModule("");
    setFilterTransactionElement("");
    setFilterSkills([]);
    setFilterMinSalary("");
    setFilterMaxSalary("");
    setFilterLabels([]);

    setEnetredNumberForSelect("");
    setSelectedNumberValidationMessage("");

    setReloadData(true);

    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedLeadIds([]);
    setSelectLeadMails("");
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "fullname",
      header: t("Name"),
    },
    {
      accessorKey: "email",
      header: t("Email"),
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "250px",
          }}
        >
          <span>{renderedCellValue}</span>
          {row.original.emailverified ? (
            <span className="d-block text-success material-symbols-outlined icon-md">
              check_circle
            </span>
          ) : (
            <span className="d-block text-danger material-symbols-outlined icon-md">
              cancel
            </span>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "subscribetomail",
      header: t("Subscribe to Mail"),
    },
    {
      accessorKey: "ownername",
      header: t("Owner"),
    },
    {
      accessorKey: "followersnames",
      header: t("Followers"),
      size: 250,
    },
    {
      accessorKey: "position",
      header: t("Position"),
    },
    {
      accessorKey: "company",
      header: t("Company"),
    },
    {
      accessorKey: "phone",
      header: t("Phone"),
    },
    {
      accessorKey: "skillsnames",
      header: t("Skills"),
    },
    {
      accessorKey: "location",
      header: t("Location"),
    },
    {
      accessorKey: "lastcontactdate",
      header: t("Last Contact Date"),
    },
    {
      accessorKey: "lastcontactsubject",
      header: t("Last Contact Subject"),
    },
    {
      accessorKey: "lastcontactchannel",
      header: t("Last Contact Channel"),
    },
    {
      accessorKey: "lastresponsedate",
      header: t("Last Response Date"),
    },
    {
      accessorKey: "lastresponsesubject",
      header: t("Last Response Subject"),
    },
    {
      accessorKey: "lastresponsechannel",
      header: t("Last Response Channel"),
    },
    {
      accessorKey: "transactionid",
      header: t("Last Transaction"),
    },
    {
      accessorKey: "module",
      header: t("Transaction Mod."),
    },
    {
      accessorKey: "element",
      header: t("Trans. Element"),
    },
    {
      accessorKey: "transactionamount",
      header: t("Trans. Amt."),
    },
    {
      accessorKey: "transactiondate",
      header: t("Trans. Date"),
    },
    {
      accessorKey: "leadcreateddate",
      header: t("Date"),
    },
    {
      accessorKey: "tagnames",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagnames != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagnames.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  /**
   * Updates the column visibility state by toggling the visibility of the column with the given accessor key.
   *
   * @param {function} newColumnState - A function that returns the accessor key of the column whose visibility is being changed.
   */
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /**
   * Represents the state of column visibility in the table.
   */
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  /**
   * Updates the column visibility state based on the new column state.
   *
   * @param {function} newColumnState - A function that returns the new column state.
   * @return {void} This function does not return anything.
   */
  const onColumnVisiblityHandler = (newColumnState) => {
    const newColumnStateName = newColumnState();
    setVisibleColoumns((prev) => ({ ...prev, ...newColumnStateName }));
  };

  //initialize the column order
  const initialColumnOrder = [
    "mrt-row-actions",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = (changedOrder) => {
    setColumnOrder(changedOrder);
  };

  //function for selecting rows manually
  const recordSelectionHandler = () => {
    if (enetredNumberForSelect !== "" || enetredNumberForSelect > 0) {
      // console.log("Lead data leangth", leadsDataList.length);
      // console.log("Entered number ----->", enetredNumberForSelect);
      // console.log("Entered row data ----->", enetredNumberForSelect);

      if (enetredNumberForSelect <= leadsDataList.length) {
        const getLeadRows = leadsDataList
          .slice(0, enetredNumberForSelect)
          .map((row) => {
            return `${row._id}/${row.email}/${row.ownerid}/${row.fullname}`;
          });

        const selectedResult = {};

        getLeadRows.forEach((item) => {
          selectedResult[item] = true;
        });

        setRowSelection(selectedResult);
      } else {
        setSelectedNumberValidationMessage(
          t("Input number should not be greater than the number of leads")
        );
      }
    } else {
      setSelectedNumberValidationMessage(
        t("Please enter a valid input number")
      );
    }
  };

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedLeadIdArr = selectedIdsArray.map((id) => {
        const [leadId] = id.split("/");
        return leadId;
      });

      const selectLeadEmails = selectedIdsArray.map((id) => {
        const [, leadMail] = id.split("/");
        return leadMail;
      });

      const selectedLeadEmailString = selectLeadEmails.join(",");

      setSelectedLeadIds(selectedLeadIdArr);
      setSelectLeadMails(selectedLeadEmailString);

      if (selectedIdsArray.length === 1) {
        const leadNameObj = selectedIdsArray[0];

        const [, , , leadName] = leadNameObj.split("/");

        setSelectedLeadNames(leadName);
      } else {
        setSelectedLeadNames("");
      }
    } else {
      setSelectedLeadIds([]);
      setSelectLeadMails("");
    }
  }, [rowSelection]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (
      (filterStartDate !== "" && filterEndDate !== "") ||
      filterLocation != "" ||
      filterLeadName != "" ||
      filterEmail != "" ||
      filterCompany != "" ||
      filterPhone != "" ||
      filterPosition != "" ||
      filterMinSalary != "" ||
      filterMaxSalary != "" ||
      filterTransactionModule != "" ||
      filterTransactionElement != "" ||
      filterSkills.length > 0 ||
      filterLabels.length > 0 ||
      filterSubsribeStatus !== ""
    ) {
      getAllLeads(true);
    }
  }, [
    filterSubsribeStatus,
    filterStartDate,
    filterEndDate,
    filterLocation,
    filterLeadName,
    filterEmail,
    filterCompany,
    filterPhone,
    filterPosition,
    filterMinSalary,
    filterMaxSalary,
    filterSkills,
    filterLabels,
    filterTransactionModule,
    filterTransactionElement,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getAllLeads(false);
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllLeads(false);
      setReloadData(false);
    }
  }, [reloadData]);

  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Leads")}
          />
          <div className="container-fluid px-lg-5">
            <CRMLeadListHeader
              userInfo={userInfo}
              reloadLeadList={refreshRecords}
              enetredNumberForSelect={enetredNumberForSelect}
              setEnetredNumberForSelect={setEnetredNumberForSelect}
              setSelectedNumberValidationMessage={
                setSelectedNumberValidationMessage
              }
              recordSelectionHandler={recordSelectionHandler}
              selectedNumberValidationMessage={selectedNumberValidationMessage}
            />

            {/* ----- lead list table and pagination section start ----- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={leadsDataList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) =>
                    `${row._id}/${row.email}/${row.ownerid}/${row.fullname}`
                  } // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }}
                  // enableRowActions // show the action column
                  enableColumnOrdering={true}
                  renderRowActionMenuItems={({ row }) => {
                    const [leadId, emailId, ownerId, leadName] =
                      row.id.split("/"); // Extracts the _id part

                    const menuItems = [
                      <MenuItem key="details">
                        <Link
                          className="dropdown-item"
                          to={`/admin/crm/lead/information/${leadId}`}
                        >
                          {t("View Details")}
                        </Link>
                      </MenuItem>,
                    ];

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      userInfo._id.toString() === ownerId.toString()
                    ) {
                      menuItems.splice(
                        2,
                        0,
                        <MenuItem key="leadtask">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/addtask/${leadId}`}
                          >
                            {t("View Task")}
                          </Link>
                        </MenuItem>,
                        <MenuItem key="leadmail">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/communication/${leadId}`}
                          >
                            {t("View Communication")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      (moduleAccess.includes("MOD_CRM") &&
                        moduleAccess.includes("MOD_OPPORTUNITY"))
                    ) {
                      menuItems.splice(
                        3,
                        0,
                        <MenuItem key="leadopportunity">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/opportunities/${leadId}`}
                          >
                            {t("View Opportunities")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      userInfo._id.toString() === ownerId.toString()
                    ) {
                      menuItems.push(
                        <MenuItem key="update">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/save/${leadId}`}
                          >
                            {t("Update Lead")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    return menuItems;
                  }} // action columns menu items
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* ----- lead list table and pagination section start ----- */}
          </div>
          {/* ----- all pop up render here ----- */}
          <CRMLeadListFilterPopup
            moduleName={"crmlead"}
            visibleColoumns={visibleColoumns}
            setVisibleColoumns={setVisibleColoumns}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            filterSubsribeStatus={filterSubsribeStatus}
            setFilterSubsribeStatus={setFilterSubsribeStatus}
            filterStartDate={filterStartDate}
            setfilterStartDate={setfilterStartDate}
            filterEndDate={filterEndDate}
            setfilterEndDate={setfilterEndDate}
            filterLeadName={filterLeadName}
            setFilterLeadName={setFilterLeadName}
            filterEmail={filterEmail}
            setFilterEmail={setFilterEmail}
            filterPhone={filterPhone}
            setFilterPhone={setFilterPhone}
            filterLocation={filterLocation}
            setFilterLocation={setFilterLocation}
            filterCompany={filterCompany}
            setFilterCompany={setFilterCompany}
            filterPosition={filterPosition}
            setFilterPosition={setFilterPosition}
            filterTransactionModule={filterTransactionModule}
            setFilterTransactionModule={setFilterTransactionModule}
            filterTransactionElement={filterTransactionElement}
            setFilterTransactionElement={setFilterTransactionElement}
            filterSkills={filterSkills}
            setFilterSkills={setFilterSkills}
            filterMinSalary={filterMinSalary}
            setFilterMinSalary={setFilterMinSalary}
            filterMaxSalary={filterMaxSalary}
            setFilterMaxSalary={setFilterMaxSalary}
            filterLabels={filterLabels}
            setFilterLabels={setFilterLabels}
            isFilterReset={isFilterReset}
            setIsFilterReset={setIsFilterReset}
            setIsNoDefaultFilter={setIsNoDefaultFilter}
          />
          {/* ------ add label modal render here ----- */}
          <AddLabelModal
            selectedIds={selectedLeadIds}
            moduleName="crmleadlist"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllLeads(false);
              }, 2500);
            }}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ------ new mail popup render here ----- */}
          <ConversationNewMailPopup
            contactPersonEmail={selectLeadMails}
            reloadList={afterEmailClose}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ------ add to list modal render here ----- */}
          <AddToListModal
            moduleName="lead"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
          />

          <AddFollowerModal
            moduleName="crmleadlist"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setTimeout(() => {
                getAllLeads(false);
              }, 2500);
            }}
          />

          <VeriFyEmailModal
            isVerifying={isVerifying}
            onVeriFy={emailVerificationHandler}
            onCancelVeriFy={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
          />

          <AddAlertModal
            selectedIds={selectedLeadIds}
            alertSubject={selectedLeadNames}
            afterModalClose={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
            moduleName={"lead"}
          />

          <LinkedinHelperModal />
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedLeadIds([]);
            }}
            onDelete={changeStatusHandler}
            moduleName={t("lead")}
          />
          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CRMLeadListBody;
