/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const TicketsByPromotion = ({
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,
  zipcodes,
  operationType,
  applyFilter,
  refreshData,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [ticketsData, setTicketsData] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const formatNumber = (unformatNum) => {
    if (unformatNum) {
      return unformatNum.toFixed(2);
    } else {
      return unformatNum;
    }
  };

  const columns = useMemo(
    () => [
      { accessorKey: "_id", header: "Promotion" },
      { accessorKey: "sold_entries", header: "Total Sales" },
    ],
    []
  );

  // structure the params received from filter in url params
  const getFilterParamsInUrl = () => {
    let urlParams = "";

    if (applyFilter === false) {
      return urlParams;
    }

    if (startDate !== "" && endDate !== "") {
      urlParams += `&filterstartdate=${startDate}&filterenddate=${endDate}`;
    }

    if (operationStartDate !== "" && operationEndDate !== "") {
      urlParams += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
    }

    if (organizations && organizations.length > 0) {
      organizations.forEach((data) => {
        urlParams += `&filterorganizations[]=${encodeURIComponent(data)}`;
      });
    }

    if (enclosures && enclosures.length > 0) {
      enclosures.forEach((data) => {
        urlParams += `&filterenclosures[]=${encodeURIComponent(data)}`;
      });
    }

    if (sectors && sectors.length > 0) {
      sectors.forEach((data) => {
        urlParams += `&filtersectors[]=${encodeURIComponent(data)}`;
      });
    }

    if (eventTypes && eventTypes.length > 0) {
      eventTypes.forEach((data) => {
        urlParams += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      });
    }

    if (events && events.length > 0) {
      events.forEach((data) => {
        urlParams += `&filterevent[]=${encodeURIComponent(data)}`;
      });
    }

    if (channels && channels.length > 0) {
      channels.forEach((data) => {
        urlParams += `&filterchannel[]=${encodeURIComponent(data)}`;
      });
    }

    if (zones && zones.length > 0) {
      zones.forEach((data) => {
        urlParams += `&filterzone[]=${encodeURIComponent(data)}`;
      });
    }

    if (zipcodes && zipcodes.length > 0) {
      zipcodes.forEach((data) => {
        urlParams += `&filterzipcode[]=${encodeURIComponent(data)}`;
      });
    }


    if (operationType && operationType.length > 0) {
      operationType.forEach((data) => {
        urlParams += `&filteroperationtype[]=${encodeURIComponent(data)}`;
      });
    }

    console.log("urlParams", urlParams);

    return urlParams;
  };


  // get records -----------------------------------
  const getRecords = async () => {
    try {

      setisLoading(true);

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_BY_PROMOTION +
        `?token=${token}&userrolestring=${userInfo.role.slug}`+
        urlParams;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setisLoading(false);

      console.log("response", response);

      if (response.status) {
        setTicketsData(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getRecords();
  }, []);



  // call all api if filter is applied again
  useEffect(() => {
    
      // call api to get filtered data
      getRecords();
  

  }, [startDate, 
      endDate,
      operationStartDate, 
      operationEndDate,
      channels, 
      organizations, 
      enclosures, 
      sectors,
      eventTypes,
      events,
      operationType,
      channels,
      zones,
      zipcodes]);

  

  return (
    <div className="tickets-year-container py-1">
      <div className="table-responsive">
        
          <div className="table-wrapper">
            {isLoading ? 
            
              (<span
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <i className="visually-hidden">Loading...</i>
                    </span>)
            :
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={ticketsData} // data from api to be displayed
              enableBottomToolbar={false}
              enableGlobalFilterModes={true}
              enablePagination={false}
              enableRowNumbers={true}
              enableRowVirtualization={true}
            />
            }
          </div>
     
      </div>
    </div>
  );
};

export default TicketsByPromotion;
